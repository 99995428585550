import { ONE_MINUTE_IN_MILLIS } from './Constants';
import { load } from '../gateway/Request';
import { debug } from './Logger';
import { getSessionCurrentScopes, getUserData, retrieveProfile } from '../gateway/Storage';

/**
 *
 * @param {string} scopes
 * @returns {number}
 */
const getLengthOfCommaSeparatedString = scopes => (scopes ? scopes.split(',').length : 0);

/**
 *
 * @returns {number}
 */
export const getCurrentNumberOfScopes = () => getLengthOfCommaSeparatedString(getSessionCurrentScopes());

/**
 *
 * @param {*} [userData]
 * @returns {number}
 */
export const getNumberOfScopesFromUserData = userData => getLengthOfCommaSeparatedString((userData || {}).scopes);

/**
 *
 * @returns {number}
 */
export const randomJitter = () => Math.floor(Math.random() * (2 * ONE_MINUTE_IN_MILLIS));

/**
 *
 * @param {string} url
 * @param {function} [resumePage]
 * @param {string} [fallback]
 * @returns {string}
 */
export function addResumePageQueryParam(url, resumePage, fallback) {
    if (!resumePage && !fallback) {
        return url;
    }

    const newUrl = url.indexOf('?') === -1 ? `${url}?` : `${url}&`;
    if (resumePage) {
        return `${newUrl}resumePage=${resumePage()}`;
    }
    return `${newUrl}resumePage=${fallback}`;
}

/**
 *
 * @param {string} path
 * @returns {Promise<unknown>}
 */
export function getPolyfills(path) {
    return new Promise((resolve) => {
        if (window.Promise && window.ShadowRoot) {
            resolve();
        } else {
            load(`${path}/webcomponents-bundle.js`).then(() => {
                resolve();
            });
        }
    });
}

/**
 *
 * @returns {undefined|string}
 */
export function getScriptPath() {
    const script = document.querySelector('script[src*="controller.min.js"]');

    if (script) {
        const tmp = script.src.split('/');
        tmp.pop();
        return tmp.join('/');
    }
    return undefined;
}
export function getMarketingId() {
    const userData = getUserData();
    if (!userData) return null;
    const profile = retrieveProfile();
    if (!profile) return userData.marketing_id;
    return userData.subprofiles[profile].marketing_id;
}
export function getCommercialId() {
    const userData = getUserData();
    const isMainProfile = userData ? !retrieveProfile() : false;
    return isMainProfile && userData.commercial_id ? userData.commercial_id : null;
}
export function getAccessibility() {
    const userData = getUserData();
    if (!userData) return null;
    const profile = retrieveProfile();
    if (!profile) return userData.accessibility;
    return userData.subprofiles[profile].accessibility;
}
/**
 *
 * @param {boolean} login
 */
export function loginAnalytics(login) {
    const mid = login ? getMarketingId() : null;
    // eslint-disable-next-line no-underscore-dangle
    if (window.digitalData && window._satellite) {
        try {
            debug('setting up analytics');
            window.digitalData.user.marketingId = mid;
            // eslint-disable-next-line no-underscore-dangle
            window._satellite.track('authentication', { interaction: login ? 'login' : 'logout', interactionDetail: 'vrt profiel widget' });
        } catch (e) {
            debug('analytics problem?', e);
        }
    } else {
        debug('no analytics');
    }
}

/**
 *
 * @param {number} num
 * @returns {string}
 */
export function numberToDefaultLoginState(num) {
    switch (num) {
        case -2: return 'disabled';
        case -1: return 'loggedOut';
        case 1: return 'loggedIn';
        default: return 'none';
    }
}

/**
 *
 * @param {string} token
 * @returns {Object}
 */
export function parseJwt(token) {
    if (!token) return {};
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`).join(''));
        return JSON.parse(jsonPayload);
    } catch (e) {
        return {};
    }
}
