import {RequestMethod} from '../util/Constants';

/**
 *
 * @param {object} obj
 * @param {string} [prefix]
 * @returns {string}
 */
const serialize = (obj, prefix) => {
    const str = [];
    const appendValue = (key, value) => {
        if (Array.isArray(value)) {
            value.forEach((arrValue) => {
                appendValue(key, arrValue);
            });
        } else {
            str.push(typeof value === 'object' ? serialize(value, key) : `${
                encodeURIComponent(key)
            }=${
                encodeURIComponent(value)
            }`);
        }
    };

    Object.keys(obj).forEach((key) => {
        const k = prefix ? `${prefix}[${key}]` : key;
        const value = obj[key];
        if (typeof value !== 'undefined') appendValue(k, value);
    });

    return str.join('&');
};

/**
 *
 * @param {string} name
 * @param {object} attribs
 * @returns {HTMLElement|HTMLAnchorElement}
 */
const create = (name, attribs) => {
    const el = document.createElement(name);

    if (attribs) {
        Object.keys(attribs).forEach((key) => {
            const at = document.createAttribute(key);
            at.value = attribs[key];
            el.setAttributeNode(at);
        });
    }
    return el;
};

/**
 *
 * @param {XMLHttpRequest} xhr
 * @param {string} method
 * @param {string} uri
 * @param {boolean} async
 * @param {object} headers
 * @param {*} data
 */
const send = (xhr, method, uri, async, headers, data) => {
    xhr.open(method, uri, async);
    Object.keys(headers).forEach((key) => {
        xhr.setRequestHeader(key, headers[key]);
    });

    xhr.send(data);
};

/**
 *
 * @param {string} method
 * @param {string} _uri
 * @param {object} [headers]
 * @param {*} [_data]
 * @param {boolean} [async]
 * @param {boolean} [withCredentials]
 * @returns {Promise<unknown>}
 */
export const request = (method = RequestMethod.GET, _uri, headers = {}, _data, async = true, withCredentials = false) => new Promise((resolve, reject) => {
        let data = _data;
        let uri = _uri;

        switch (method) {
            case RequestMethod.GET:
                if (typeof data === 'object') {
                    data = serialize(data);
                }
                if (data) {
                    uri += create('a', {
                        href: uri,
                    }).search ? '&' : '?';
                    uri += data;
                    data = null;
                }
                break;
            case RequestMethod.DELETE:
                break;
            default:
                if (headers['Content-Type'] === 'application/x-www-form-urlencoded') {
                    data = serialize(data);
                }
                break;
        }

        const xhr = new XMLHttpRequest();
        xhr.withCredentials = withCredentials;

        xhr.addEventListener('load', () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response);
            } else {
                reject(new Error(`${xhr.response}`, {cause: xhr.status}));
            }
        });
        xhr.addEventListener('error', (event) => {
            reject(new Error(`${xhr}-${event}-onerror-${xhr.status}:${xhr.statusText}`));
        });
        send(xhr, method, uri, async, headers, data);
    });

/**
 *
 * @param {string} uri
 * @param {string} [type]
 * @param {string} [parent]
 * @param {*} [moreAttribs]
 * @returns {Promise<unknown>}
 */
export const load = function _load(uri, type = 'script', parent = 'head', moreAttribs = {}) {
    return new Promise((resolve, reject) => {
        let atName = 'href';

        if ('audio|embed|iframe|img|input|script|source|track|video'.indexOf(type) !== -1) {
            atName = 'src';
        }
        const attribs = {
            [atName]: uri,
        };

        if (type === 'link' && uri.match(/\.css$/)) {
            attribs.rel = 'stylesheet';
        }

        if (moreAttribs !== null) {
            Object.keys(moreAttribs)
                .forEach((key) => {
                    attribs[key] = moreAttribs[key];
                });
        }
        const el = create(type, attribs);
        el.addEventListener('load', () => {
            resolve(el);
        });
        el.addEventListener('error', () => {
            reject(new Error(el.id));
        });
        const attachTo = document.querySelector(parent);
        attachTo.appendChild(el);
    });
};
