import { getSessionInteractionRequired } from '../gateway/Storage';

const DEV_SUFFIX = '-dev';
const STAG_SUFFIX = '-stag';
const SSO_DEMO_SITE_DEV = 'ssodemo-dev';

/**
 * @typedef config
 * @property {string} [ssoPath]
 * @property {string} clientId
 * @property {string|function} [resumePage]
 * @property {boolean} [autoRefreshOff]
 * @property {boolean} [autoLoginOff]
 * @property {boolean} [loadWidget]
 * @property {object} [Qualifio]
 * @property {string} Qualifio.campaignId
 * @property {string} Qualifio.domain
 * @property {string} [Qualifio.env]
 */

/**
 *
 * @returns {config}
 */
const vrtSsoConfig = () => {
    const VRT = window.VRT || {};
    const cfg = VRT.SSOConfig || {};

    if (!cfg.clientId) {
        throw Error(`No client id found. Expected to find a clientId value in VRT.SSOConfig, instead it contains ${JSON.stringify(VRT.SSOConfig)}.
        If you do not know what your clientId is, please contact the VRT Profile team.`);
    }
    return cfg;
};
/**
 *
 * @param {config} cfg
 * @returns {string|function}
 */
const findResumePage = (cfg) => {
    if (cfg.resumePage) {
        return typeof cfg.resumePage === 'function' ? cfg.resumePage : () => cfg.resumePage;
    }
    return undefined;
};
/**
 *
 * @param {config} cfg
 * @returns {string}
 */
const findEnvironment = (cfg) => {
    if (!cfg.clientId) {
        return undefined;
    }
    // only our own demo client links with login dev. all others link with stag (or prod)
    if (cfg.clientId === SSO_DEMO_SITE_DEV) {
        return 'dev';
    }
    if (cfg.clientId.indexOf(DEV_SUFFIX) === -1 && cfg.clientId.indexOf(STAG_SUFFIX) === -1) {
        return 'prod';
    }
    return 'stag';
};

/**
 *
 * @param {config} cfg
 * @returns {{environment: string, resumePage: (string|Function), autoRefreshOff: (boolean), autoLoginOff: (boolean), ssoPath: (string), loadWidget: (boolean), addedScope: (string[])}}
 */
const createBaseConfig = (cfg) => {
    const IR = JSON.parse(getSessionInteractionRequired() || '[]');
    return {
        ssoPath: cfg.ssoPath || 'sso',
        environment: findEnvironment(cfg),
        resumePage: findResumePage(cfg),
        autoRefreshOff: cfg.autoRefreshOff,
        autoLoginOff: cfg.autoLoginOff,
        loadWidget: cfg.loadWidget === false ? false : cfg.loadWidget || true,
        addedScope: (cfg.addedScope || '').split(',').map(scope => scope.trim()).filter(scope => !IR.includes(scope)).filter(Boolean),
    };
};

export default function createConfig() {
    const cfg = vrtSsoConfig();
    return createBaseConfig(cfg);
}
