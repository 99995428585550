/* eslint no-console: 0 */

/**
 * @typedef {import("../controller/Config").config} cfg
 */

const DEV_SUFFIX = '-dev';
const STAG_SUFFIX = '-stag';

let debugOn;
let nonProd;

/**
 * @returns {cfg|{}}
 */
const getSsoConfig = () => {
    const VRT = window.VRT || {};
    return VRT.SSOConfig || {};
};

/**
 * @returns {boolean}
 */
const isDebug = () => {
    if (debugOn) {
        return debugOn;
    }
    return getSsoConfig().debug;
};

/**
 * @returns {boolean|undefined}
 */
const isNonProd = () => {
    if (nonProd) {
        return nonProd;
    }
    const cfg = getSsoConfig();
    return cfg.clientId ? cfg.clientId.indexOf(DEV_SUFFIX) !== -1 || cfg.clientId.indexOf(STAG_SUFFIX) !== -1 : undefined;
};

/**
 * Log - or do not - depending on debugOn, if it exists. Else check the environment for defaults (logging for dev and stag).
 * @param {function} logger
 * @returns {function(...*): void}
 */
const basicLog = logger => (...message) => {
    nonProd = isNonProd();
    debugOn = isDebug();

    if (debugOn || (debugOn === undefined && nonProd)) {
        // eslint-disable-next-line prefer-spread
        const x = (new Error()).stack;
        logger.apply(null, [`%c[sso|${x.split('\n')[1].split(/[/@]/)[0]}]`, 'background:rgb(93, 252, 113);color:rgb(23, 41, 74);', ...message]);
    }
};

export const debug = basicLog(console.debug);
export const info = basicLog(console.info);
export const warn = basicLog(console.warn);
export const error = basicLog(console.error);
export const log = basicLog(console.log);
