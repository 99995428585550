
import {
    getChildLoginCode, clearChildLoginCode, setChildLoginCode, clearSessionStorage,
    setNoInteraction, clearLiveInteraction, retrieveProfile, getSessionState,
    getUserData,
    setUserData,
} from '../gateway/Storage';
import { request } from '../gateway/Request';
import { RequestMethod, PROFILE_TYPE } from '../util/Constants';
import { error, debug } from '../util/Logger';
import {
    attemptTokenRefreshIfNeeded, fireEvent, getCurrentProfile, getProfile,
} from './Refresh';
import { getAccessTokenCookie } from '../gateway/Cookies';
import LoginState from '../util/LoginState';
import { addResumePageQueryParam } from '../util/Util';

const getNewChildLoginCodeIfNeeded = (config, geoLocation) => new Promise((resolve, reject) => {
    const code = getChildLoginCode();
    if (code && (new Date(code.expiration_time) > Date.now())) {
        resolve(code.login_code);
    } else {
        clearChildLoginCode();
        request(RequestMethod.POST, `/${config.ssoPath}/subprofileauth`, { 'Content-Type': 'application/x-www-form-urlencoded' }, { geo_location: geoLocation || 'BE' }, true, true)
            .then((rawData) => {
                const data = JSON.parse(rawData);
                setChildLoginCode(data);
                resolve(data.login_code);
            }).catch((e) => {
                error('request error', e);
                reject(e);
            });
    }
});
const getAccessToken = config => (cb) => {
    attemptTokenRefreshIfNeeded(`/${config.ssoPath}/refresh`, null, null, (err) => {
        if (err) {
            return cb(null);
        }
        return cb(getAccessTokenCookie());
    });
};
export const loginAsChild = config => async (geoLocation) => {
    if (getSessionState() === LoginState.loggedIn) {
        return new Error('already logged in');
    }
    await getNewChildLoginCodeIfNeeded(config, geoLocation)
        .then((loginCode) => {
            clearSessionStorage();
            setNoInteraction();
            clearLiveInteraction();
            const url = `/${config.ssoPath}/login?login_code=${loginCode}`;
            window.location.href = addResumePageQueryParam(url, config.resumePage, window.location.href);
        })
        .catch((e) => {
            error('loginAsChild error', e);
            clearChildLoginCode();
        });
    return null;
};

export const createSubProfile = config => (cb) => {
    getAccessToken(config)((token) => {
        if (!token) return cb(null);
        const url = `/${config.ssoPath}/kinderprofielen`;
        const headers = { Authorization: `Bearer ${token}` };
        request(RequestMethod.POST, url, headers, {}, true, true)
            .then((result) => {
                const jsonresult = JSON.parse(result);
                cb(jsonresult);
            }).catch((err) => {
                debug('error', err);
                cb(null);
            });
        return null;
    });
};

export const profileSwitchAllowed = config => (pincode, cb = () => { }) => {
    const userData = getUserData();
    const currentProfile = getCurrentProfile(userData);
    if (currentProfile.profileType === PROFILE_TYPE.MAIN) { cb(null, true); return; }
    getAccessToken(config)((token) => {
        if (!token) return cb({ Error: 'no accessToken' });
        const pinobject = pincode ? JSON.stringify({ pincode }) : null;
        request(RequestMethod.POST, `${config.ssoPath}/profileswitch`, { Authorization: `Bearer ${token}` }, pinobject)
            .then((result) => {
                const { allowed } = JSON.parse(result);
                cb(null, allowed);
            })
            .catch((err) => {
                debug('fout: ', err);
                cb({ ...JSON.parse(err.message || '{"Error":""}'), code: err.cause });
            });
        return null;
    });
};

export const isChild = () => {
    const userData = getUserData();
    const profile = retrieveProfile();
    return userData && profile;
};

export const getKidsInteraction = config => (cb = () => { }) => {
    if (getSessionState() !== LoginState.loggedIn) return cb(null, null);
    if (!isChild()) return cb(null, null);
    getAccessToken(config)((token) => {
        if (!token) return cb({ Error: 'no accessToken' });
        request(RequestMethod.GET, `${config.ssoPath}/kids_interaction`, { Authorization: `Bearer ${token}` })
            .then((result) => {
                cb(null, result);
            })
            .catch((err) => {
                debug('fout: ', err);
                cb({ ...JSON.parse(err.message || '{"Error":""}'), code: err.cause });
            });
        return null;
    });
    return null;
};

export const requestKidsInteraction = config => (cb = () => { }) => {
    if (getSessionState() !== LoginState.loggedIn) return cb(null, null);
    if (!isChild()) return cb(null, null);
    getAccessToken(config)((token) => {
        if (!token) return cb({ Error: 'no accessToken' });
        request(RequestMethod.POST, `${config.ssoPath}/request_kids_interaction`, { Authorization: `Bearer ${token}` })
            .then((result) => {
                cb(null, result);
            })
            .catch((err) => {
                debug('fout: ', err);
                cb({ ...JSON.parse(err.message || '{"Error":""}'), code: err.cause });
            });
        return null;
    });
    return null;
};

export const generateKidsUsernames = config => (cb = () => { }) => {
    if (getSessionState() !== LoginState.loggedIn) return cb(null, null);
    if (!isChild()) return cb(null, null);
    getAccessToken(config)((token) => {
        if (!token) return cb({ Error: 'no accessToken' });
        request(RequestMethod.GET, `${config.ssoPath}/generate_kids_usernames`, { Authorization: `Bearer ${token}` })
            .then((result) => {
                cb(null, JSON.parse(result));
            })
            .catch((err) => {
                debug('fout: ', err);
                cb({ ...JSON.parse(err.message || '{"Error":""}'), code: err.cause });
            });
        return null;
    });
    return null;
};

export const saveKidsUsername = config => (ids, cb = () => { }) => {
    if (getSessionState() !== LoginState.loggedIn) return cb(null, null);
    if (!isChild()) return cb(null, null);
    getAccessToken(config)((token) => {
        const payload = ids ? JSON.stringify({ ids }) : null;
        request(RequestMethod.POST, `${config.ssoPath}/kids_username`, { Authorization: `Bearer ${token}` }, payload)
            .then((result) => {
                debug(result);
                const userdata = getUserData();
                const profile = getProfile();
                userdata.subprofiles[profile].username = result;
                setUserData(userdata);
                fireEvent(false);
                cb(null, result);
            })
            .catch((err) => {
                debug('fout: ', err);
                cb({ ...JSON.parse(err.message || '{"Error":""}'), code: err.cause });
            });
    });
    return null;
};
