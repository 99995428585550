import { COMMA } from '../util/Constants';
import { parseJwt } from '../util/Util';

/**
 *
 * @returns {string}
 */
const getClientIdFromConfig = () => ((window.VRT || {}).SSOConfig || {}).clientId || '';
/**
 *
 * @returns {string}
 */
const dataCookieName = () => `${getClientIdFromConfig()}_profile_dt`;
/**
 *
 * @returns {string}
 */
const expiryCookieName = () => `${getClientIdFromConfig()}_profile_et`;
/**
 *
 * @returns {string}
 */
const videoCookieName = () => `${getClientIdFromConfig()}_profile_vt`;
/**
 *
 * @returns {string}
 */
const accessTokenCookieName = () => `${getClientIdFromConfig()}_profile_at`;
/**
 *
 * @param {string} name
 * @returns {string|null}
 */
const getCookie = name => document.cookie.split(';')
    .filter(cookie => cookie.trim().startsWith(`${name}=`))
    .map(co => decodeURIComponent(co.substr(co.indexOf('=') + 1)))
    .shift() || null;

/**
 *
 * @returns {string|null}
 */
export const getExpiryCookie = () => getCookie(expiryCookieName());


/**
 *
 * @returns {string|null}
 */
export const getVideoCookie = () => getCookie(videoCookieName());

/**
 *
 * @returns {string|null}
 */
export const getAccessTokenCookie = () => getCookie(accessTokenCookieName());

/**
 *
 * @returns {string[]}
 */
export const getScopes = () => {
    const {scopes} = parseJwt(getAccessTokenCookie());
    return scopes ? [...new Set(['openid', 'mid'].concat(scopes.split(COMMA)))] : ['openid', 'mid'];
};

/**
 *
 * @param {string} name
 */
const deleteCookie = (name) => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `${name}=;path=/;domain=${window.location.hostname};expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `${name}=;path=/;domain=.${window.location.hostname};expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const deleteCookies = () => {
    deleteCookie(dataCookieName());
    deleteCookie(expiryCookieName());
};

/**
 *
 * @param {string|string[]}scope
 * @returns {string[]}
 */
const scopeAsArray = (scope) => {
    if (typeof scope === 'string') {
        return scope.split(/[ ,]/).filter(Boolean);
    }
    return scope;
};

/**
 *
 * @param {string|string[]} [customScope]
 * @returns {string[]}
 */
export const getScopesWith = (customScope) => {
    const scopeSet = new Set(getScopes().concat(scopeAsArray(customScope || []).flat().filter(Boolean)));
    return [...scopeSet];
};
