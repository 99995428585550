import { load } from './gateway/Request';
import {
    getSessionState,
    clearSessionState,
    retrieveProfile,
    getUserData,
} from './gateway/Storage';
import {
    forceTokenRefresh,
    swtchProfile,
    getProfile,
    getLiveInteractionToken,
    getAuthType,
    refreshUserData,
} from './controller/Refresh';
import {
    getPolyfills, getScriptPath, getMarketingId, getCommercialId, getAccessibility,
} from './util/Util';
import { error } from './util/Logger';
import loadFont from './util/FontLoader';
import createConfig from './controller/Config';
import {
    addScopeSilent, getAccessToken, getIdToken, getNewIdToken, getVideoToken, logout, tryLogin, tryAutoLoginWithIdentityToken,
} from './controller/Login';
import {
    deleteCookies,
    getAccessTokenCookie,
    getScopes,
} from './gateway/Cookies';
import {
    addScopePrompting,
    manualLogin,
    newAccount,
} from './controller/LoginCalls';
import { fireStateChangeEvent } from './controller/State';
import {
    profileSwitchAllowed,
    createSubProfile,
    loginAsChild,
    requestKidsInteraction,
    getKidsInteraction,
    generateKidsUsernames,
    saveKidsUsername,
} from './controller/Kids';

window.VRT = window.VRT || {};

const { VRT } = window;

if (VRT.SSOController) {
    error('VRT.SSOController has already been taken. Exiting...');
} else {
    VRT.SSOController = (function _SSOController() {
        let config = null;
        let initialized = false;
        const styles = {};

        /**
         * @callback eventListener
         * @param {Event} event
         */

        /**
         * @typedef eventOptions
         * @property {boolean} [capture]
         * @property {boolean} [once]
         * @property {boolean} [passive]
         * @property {AbortSignal} [signal]
         */

        /**
         * @param {string} type
         * @param {eventListener} listener
         * @param {eventOptions} [options]
         */
        function addEventListener(type, listener, options) {
            window.addEventListener(type, listener, options);
            if (initialized) {
                setTimeout(() => { fireStateChangeEvent(null, null, listener); }, 0);
            }
        }

        /**
         * @param {string} type
         * @param {eventListener} listener
         * @param options
         */
        function removeEventListener(type, listener, options) {
            window.removeEventListener(type, listener, options);
        }

        /**
         *
         * @param {string} styleString
         * @param {string} styleID
         * @returns {*}
         */
        function setStyle(styleString, styleID) {
            let style;
            let stID;
            if (!styleID || !styles[styleID]) {
                style = document.createElement('style');
                stID = `style${Object.keys(styles).length + 1}`;
                styles[stID] = style;
                document.head.appendChild(style);
            } else {
                stID = styleID;
                style = styles[styleID];
            }
            const str = styleString.replace(/<styleID>/g, stID);
            // in case rewriting the textcontent makes the browser rerender the whole screen
            if (style.textContent !== str) {
                style.textContent = str;
            }
            return stID;
        }
        function buildProfileSiteBaseUrl() {
            return `https://profiel${VRT.SSOController.environment !== 'prod' ? `-${VRT.SSOController.environment}` : ''}.vrt.be`;
        }
        function recieveMessage(event) {
            if (event.origin && event.origin === buildProfileSiteBaseUrl() && event.data && event.data === 'fetchUserData') {
                const scopes = getScopes();
                if (scopes.indexOf('subprofiles') > -1) {
                    refreshUserData(config)();
                }
            }
        }

        function SSOController() {
            window.addEventListener('message', recieveMessage, false);
            config = createConfig();
            if (config.autoLoginOff) clearSessionState();
            const localProfile = retrieveProfile();
            if (localProfile) swtchProfile(localProfile);
            if (config.loadWidget) {
                const script = getScriptPath();
                if (script) {
                    loadFont(script);
                    getPolyfills(script).then(() => {
                        if (!config.autoLoginOff) {
                            tryLogin(config)(true, () => {
                                initialized = true;
                            });
                        }
                        const widgetType = config.loadWidget === true ? 'widget' : config.loadWidget;
                        load(`${script}/${widgetType}.min.js`).then(() => {
                            window.dispatchEvent(new CustomEvent('sso-widget-loaded', { loaded: true }));
                        });
                    });
                }
            } else if (!config.autoLoginOff) {
                tryLogin(config)(true, () => {
                    initialized = true;
                });
            } else {
                deleteCookies();
            }

            SSOController.setStyle = setStyle;
            SSOController.addEventListener = addEventListener;
            SSOController.removeEventListener = removeEventListener;
            SSOController.environment = config.environment;
            // eslint-disable-next-line no-use-before-define
            setUserFunctions();

            window.dispatchEvent(new CustomEvent('ssoControllerBootstrapped', { bootstrapped: true }));
        }
        function updateSSOConfig() {
            config = createConfig();
            // eslint-disable-next-line no-use-before-define
            setUserFunctions();
        }

        function setUserFunctions() {
            SSOController.tryLogin = tryLogin(config);
            SSOController.forceTokenRefresh = forceTokenRefresh(config);
            SSOController.manualLogin = manualLogin(config);
            SSOController.newAccount = newAccount(config);
            SSOController.logout = logout(config);

            SSOController.getNewIdToken = getNewIdToken(config);
            SSOController.getIdToken = getIdToken(config);
            SSOController.getAccessToken = getAccessToken(config);
            SSOController.getVideoToken = getVideoToken(config);
            SSOController.getUserData = () => {
                const userData = getUserData();
                if (!userData) return null;
                userData.id_token = getAccessTokenCookie();
                return userData;
            };

            SSOController.getState = getSessionState;

            SSOController.addScopePrompting = addScopePrompting(config, SSOController.getIdToken);
            SSOController.addScopeSilent = addScopeSilent(config);
            SSOController.tryLoginWithIdentityToken = tryAutoLoginWithIdentityToken(config);
            SSOController.switchProfile = (profile, callback) => { swtchProfile(profile); forceTokenRefresh(config, true)(callback); };
            SSOController.profileSwitchAllowed = profileSwitchAllowed(config);
            SSOController.getProfile = getProfile;
            SSOController.getMarketingId = getMarketingId;
            SSOController.getCommercialId = getCommercialId;

            SSOController.createSubProfile = createSubProfile(config);
            SSOController.updateSSOConfig = updateSSOConfig;
            SSOController.getAccessibility = getAccessibility;
            SSOController.getLiveInteractionToken = getLiveInteractionToken(config);
            SSOController.loginAsChild = loginAsChild(config);
            SSOController.getAuthType = getAuthType;
            SSOController.getKidsInteraction = getKidsInteraction(config);
            SSOController.requestKidsInteraction = requestKidsInteraction(config);
            SSOController.generateKidsUsernames = generateKidsUsernames(config);
            SSOController.saveKidsUsername = saveKidsUsername(config);
        }

        // startup
        return SSOController;
    }());
    // eslint-disable-next-line no-new
    new VRT.SSOController();
}
